import React from "react";
import { useTranslation } from "react-i18next";
import { handleOpenSearchAnimation } from "../animations/animation";
import { NavLink } from "react-router-dom";

const PromoLanding = ({ fullpageApi }) => {
  const { t } = useTranslation();

  return (
    <section
      className='promo'
    >
      <div className='promo-content promo-landing-content'>
        {/* <h6 className='promo-sub-title'> {t("WELCOME_TO")}</h6> */}
        <h3 className='promo-title promo-landing-title'>
          World’s <br /> Local <br /> Trade Partner
        </h3>
        <NavLink
          className='btn btn-outline-light promo-action-button'
          to={{ pathname: `/products/` }}
        >
          {t('EXPLORE')}
        </NavLink>
      </div>
      <video
          data-keepplaying
          autoPlay
          muted
          loop
          playsInline
          preload='yes'
          className='promo-video'
          // poster='/assets/img/promo/1.jpg'
        >
          <source
            src="//stream.ayanaholding.com/623216594754676659802980.mp4"
            type='video/mp4'
          />
        </video>
    </section>
  );
};

export default PromoLanding;


//128.199.84.96:5080/LiveApp/play.html?name=